p{
    font-family: 'Public Sans', sans-serif;
}
.course-overview p{
    text-align: justify;
}
.course-overview{
    background-color: #ffffff;
    box-shadow: 1px 4px 20px -2px rgba(0,0,0,0.1);
    padding: 17px;
}
.coursecontent h1,.coursecontent h2,.coursecontent h3,.coursecontent h4,.coursecontent h5,.coursecontent h6{
    font-family: inherit;
    letter-spacing: 0px;
    font-size: 16px !important;
    font-weight: normal;
    padding-bottom:0px;
  
  }
  .course-overview img{
    height: 308px;
    object-fit: cover;
    object-position: top;
    margin-bottom: 12px;
  }
.course-overview h1, .course-overview h2,.course-overview h3,.course-overview h4,.course-overview h5,.course-overview h6{
    font-family: inherit;
    letter-spacing: 0px;
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 10px;
}

.nsm7Bb-HzV7m-LgbsSe {
  border: 0px solid;
}
