.branches{
    text-align: center;
}
.branches:before {
    content: "";
    background: red;
    height: 5px;
    width: 60px;
    position: absolute;
    transform: translate(-44px, 50px);
}
.branches h2{
    font-size: 37px;
}


